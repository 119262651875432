<template>
  <div style="background: white">
    <v-sheet class="overflow-hidden">
      <Loading :loadingCounter="loadingCounter" />
      <!-- 倉庫マスタ登録 -->
      <NavBar v-if="btnNmToggle == '1'" tittle="label.lbl_warehouseMasterAdd" />
      <!-- 倉庫マスタ修正 -->
      <NavBar v-if="btnNmToggle == '2'" tittle="label.lbl_warehouseMasterUpdate" />
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="officeNames"
                :label="$t('label.lbl_salesOffice')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 倉庫コード -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="warehouseCd"
                :rules="[rules.inputRequired]"
                maxlength="6"
                :label="$t('label.lbl_warehouseCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <span class="require">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 倉庫名称 -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                counter="50"
                v-model="warehouseName"
                maxlength="50"
                :label="$t('label.lbl_warehouseName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.limitLength, rules.inputRequired]"
                clearable
              />
            </div>
            <span class="require">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 倉庫名称全角カナ -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                counter="100"
                v-model="warehouseNameKana"
                maxlength="100"
                :label="$t('label.lbl_warehousenameFull-width')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.isZenkakuKana, rules.inputRequired]"
                clearable
              />
            </div>
            <span class="require">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 倉庫名称表示用 -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                outlined
                dense
                counter="20"
                v-model="warehouseNameShort"
                maxlength="20"
                :label="$t('label.lbl_warehouseNameDisplay')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputRequired]"
                clearable
              />
            </div>
            <span class="require">*</span>
          </v-row>
          <v-col cols="4" />
          <!-- 有効開始日時-->
          <v-row class="search-row first-search-item">
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_validStartDateAndTime')"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有効終了日時 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validTo"
                class="txt-single date-style"
                :label="$t('label.lbl_validEndDateAndTime')"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validToCal" @input="validToMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 倉庫種別 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="warehouseKind"
                :items="WarehouseKindList"
                :label="$t('label.lbl_warehouseKinds')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 社有外傭区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="warehouseOwnerDiv"
                :items="companyOwnedList"
                :label="$t('label.lbl_companyOwnedGaijin')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                ></v-autocomplete
              >
            </div>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 延床面積（㎡） -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                outlined
                dense
                v-model="floorSpace"
                @change="changefloorspace"
                @click="clickPop(floorSpace)"
                @blur="LimiteNumM"
                :label="labelfloorSpace"
                class="input-number"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputNumber_m2]"
                persistent-hint
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 延床面積（坪） -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="floorSpaceTsubo"
                @click="clickPop(floorSpaceTsubo)"
                @change="changefloorspaceTubo"
                @blur="Limited3000"
                :label="labelfloorSpaceTubo"
                class="input-number"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputNumber_m2]"
                clearable
                :error-messages="alertMessage2"
              />
            </div>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 床耐荷重 -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                outlined
                dense
                v-model="floorWithstandload"
                @click="clickPop(floorWithstandload)"
                @change="changefloorWithstandload"
                :label="labelfloorWithstandload"
                class="input-number"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputNumbers]"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 天井高 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="ceilingheight"
                @click="clickPop(ceilingheight)"
                @change="changeceilingheight"
                :label="labelceilingheight"
                class="input-number"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputNumber_m]"
                clearable
              />
            </div>
          </v-row>
          <v-col cols="4" />
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div v-if="btnNmToggle == '1'" class="btn-search-area" style="float: left">
                <!-- 登録ボタン -->
                <v-btn color="primary" id="get-search" class="api-btn" @click="warehouseAdd()">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
              <div v-if="btndisplay == true">
                <div v-if="btnNmToggle == '2'" class="btn-search-area" style="float: left">
                  <!-- 修正ボタン -->
                  <v-btn color="primary" id="get-search" class="api-btn" @click="warehouseAdd()">{{
                    $t("btn.btn_fix")
                  }}</v-btn>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> キャンセル </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :sevenPageFlag="infoDialog.sevenPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_001,
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ラベル用
    labelfloorSpace:
      i18n.tc("label.lbl_totalFloorAreaSqmetre") + `(${appConfig.CONSTANT.TOTALFLOORAREASQMETER})`,
    labelfloorSpaceTubo:
      i18n.tc("label.lbl_totalFloorAreaTsubo") +
      `(${appConfig.CONSTANT.TOTALFLOORAREASQMETERTSUBO})`,
    labelfloorWithstandload:
      i18n.tc("label.lbl_Withstandload") + `(${appConfig.CONSTANT.FROORWITHSTANDLOAD})`,
    labelceilingheight:
      i18n.tc("label.lbl_warehouseheight") + `(${appConfig.CONSTANT.CELILINGHEIGHT})`,
    // 有効終了日時バリデーション
    alertMessage: "",
    alertMessage2: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    officeNames: "",
    supplierList: [],
    // メニュー
    openMenu: null,
    // ログインしているユーザーの営業所と参照したい倉庫の営業所が同一であれば登録/修正ボタンを表示
    btndisplay: false,
    // 有効開始日時
    validFrom: "",
    validFromCal: "",
    validFromMenu: false,
    // 有効終了日時
    validTo: "",
    validToCal: "",
    validToMenu: false,
    // 倉庫種別リスト
    WarehouseKindList: [],
    // 社有外傭区分リスト
    companyOwnedList: [],
    // 倉庫コード
    warehouseCd: "",
    // 倉庫名称
    warehouseName: "",
    // 倉庫名称表示用
    warehouseNameShort: "",
    // 倉庫名称全角カナ
    warehouseNameKana: "",
    // 営業所
    officeName: "",
    // 営業所SID
    officeSid: "",
    // ログインしているユーザーの営業所SID
    loginofficeSid: "",
    // 倉庫SID
    warehouseSid: "",
    // 倉庫種別
    warehouseKind: "",
    // 社有外傭区分
    warehouseOwnerDiv: "",
    // 延床面積（坪）
    floorSpaceTsubo: "",
    // 延床面積（㎡）
    floorSpace: "",
    // 床耐荷重
    floorWithstandload: "",
    // 天井高さ
    ceilingheight: "",
    // 更新日
    updateDatetime: "",
    // 前画面の名前を入れる変数
    beforePage: "",
    // 登録/更新ボタン切替
    btnNmToggle: "1",
    e1: 1,
  }),
  methods: {
    // 初期化
    init() {
      this.getMstCode();
      this.getcompanyOwnedGaijin();

      // 戻るボタン一時保存用
      this.previousSearchFilter = this.$route.params.searchFilter;

      // 前画面を判断するための変数
      this.beforePage = this.$route.params.name;
      if (this.beforePage == "MstWarehouseList") {
        this.btnNmToggle = "2"; // タイトル/ボタンを更新に変更する

        // 画面表示用
        this.warehouseSid = this.$route.params.warehouseSid;
        this.officeSid = this.$route.params.officeSid;
        this.loginofficeSid = this.$route.params.loginofficeSid;
        this.officeName = this.$route.params.officeName;
        this.warehouseCd = this.$route.params.warehouseCd;
        this.warehouseName = this.$route.params.warehouseName;
        this.warehouseNameShort = this.$route.params.warehouseNameShort;
        this.warehouseNameKana = this.$route.params.warehouseNameKana;
        this.warehouseKind = this.$route.params.warehouseKind;
        this.warehouseOwnerDiv = this.$route.params.warehouseOwnerDiv;
        this.validFrom = dateTimeHelper.convertUTC2JST(this.$route.params.validFrom).substr(0, 10);
        this.validTo = this.$route.params.validTo.substr(0, 10);
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.$route.params.validFrom.substr(0, 10))
        );
        this.validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.$route.params.validTo.substr(0, 10))
        );
        this.updateDatetime = this.$route.params.updateDatetime;
        // 延床面積（㎡）文字列に変換
        this.floorSpace = this.$route.params.totalFloorAreaSqmetre;
        this.floorSpace = String(commonUtil.formatToCurrency(Number(this.floorSpace)));
        // 床耐荷重（t/㎡）
        this.floorWithstandload = this.$route.params.floorLoadCapacity;
        this.floorWithstandload = String(
          commonUtil.formatToCurrency(Number(this.floorWithstandload))
        );
        // 天井高（m）
        this.ceilingheight = this.$route.params.ceilingHeight;
        this.ceilingheight = String(commonUtil.formatToCurrency(Number(this.ceilingheight)));
        // 延床面積（坪）
        this.floorSpaceTsubo = this.$route.params.floorSpaceTsubo;
        this.floorSpaceTsubo = String(commonUtil.formatToCurrency(Number(this.floorSpaceTsubo)));
        // ログインしているユーザーの所属する営業所だとしたら修正ボタンを表示する
        if (this.loginofficeSid == this.officeSid) {
          this.btndisplay = true;
        }
      } else {
        this.btnNmToggle = "1"; // タイトル/ボタンを登録に変更する
        this.officeSid = this.$route.params.officeSid;
        this.validFrom = dateTimeHelper.convertJST();
        this.validTo = dateTimeHelper.convertJST();
        this.validFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST("2099/12/31".substr(0, 10))
        );
      }
      this.getCustomInfo();
    },
    //登録ボタン押下
    warehouseAdd() {
      if (
        // 有効終了日が有効開始日時よりも先日付であるかチェック
        new Date(this.validFrom).getTime() > new Date(this.validTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputValiFrom");
      } else if (this.$refs.form.validate()) {
        this.alertMessage = "";
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },
    // 戻るボタン押下(倉庫マスタ一覧へ遷移)
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;
      // 元画面が一覧の場合
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_002,
        params: {
          searchFilter: this.previousSearchFilter,
          backpage: "MstWarehouseAdd",
        },
      });
    },
    LimiteNumM(val) {
      let numM = commonUtil.zen2han(val.srcElement._value).replaceAll(",", "");
      if (numM < 100000000) {
        this.alertMessage2 = "";
      }
    },
    Limited3000(val) {
      let numM = commonUtil.zen2han(this.floorSpace).replaceAll(",", "");
      let num = commonUtil.zen2han(val.srcElement._value).replaceAll(",", "");
      if (numM < 100000000 && num <= 33000000) {
        this.alertMessage2 = "";
        return true;
      } else if (num >= 30250778.95) {
        this.alertMessage2 = i18n.tc("check.chk_limitArea");
        return;
      }
      this.alertMessage2 = "";
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 倉庫マスタPOST
    postApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      if (
        // 有効終了日が有効開始日時よりも先日付であるかチェック
        new Date(this.validFrom).getTime() > new Date(this.validTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromValidate");
      } else {
        if (this.$refs.form.validate()) {
          this.alertMessage = "";

          const body = this.$httpClient.createRequestBodyConfig();
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_COM_007;

          if (this.beforePage == "MstWarehouseList") {
            body.reqIdv.warehouseSid = this.warehouseSid ? this.warehouseSid : ""; //倉庫SID
            body.reqIdv.officeSid = this.officeSid ? this.officeSid : ""; //営業所SID
            body.reqIdv.warehouseCd = this.warehouseCd ? this.warehouseCd : ""; //倉庫コード
            body.reqIdv.warehouseName = this.warehouseName; //倉庫名称
            body.reqIdv.warehouseNameShort = this.warehouseNameShort; //倉庫名称表示用
            body.reqIdv.warehouseNameKana = this.warehouseNameKana; //倉庫名称カナ
            body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.validFrom); //有効開始日時
            body.reqIdv.validTo = dateTimeHelper.convertUTC(this.validTo); //有効終了日時
            body.reqIdv.warehouseOwnerDiv = this.warehouseOwnerDiv ? this.warehouseOwnerDiv : ""; //社有外傭区分
            body.reqIdv.warehouseKind = this.warehouseKind ? this.warehouseKind : "";
            body.reqIdv.floorSpace = Number(this.floorSpace.replaceAll(",", ""))
              ? this.floorSpace.replaceAll(",", "")
              : ""; //延床面積(㎡)
            body.reqIdv.floorSpaceTsubo = Number(this.floorSpaceTsubo.replaceAll(",", ""))
              ? this.floorSpaceTsubo.replaceAll(",", "")
              : ""; //延床面積(坪)
            body.reqIdv.baseUnit = "03"; //単位
            body.reqIdv.floorLoadCapacity = Number(this.floorWithstandload.replaceAll(",", ""))
              ? this.floorWithstandload.replaceAll(",", "")
              : ""; //床耐荷重
            body.reqIdv.ceilingHeight = Number(this.ceilingheight.replaceAll(",", ""))
              ? this.ceilingheight.replaceAll(",", "")
              : ""; //天井高
            body.reqIdv.deleteFlg = "1"; //削除フラグ1
            body.reqIdv.updateDatetime = this.updateDatetime; //更新日
          } else {
            body.reqIdv.officeSid = this.officeSid ? this.officeSid : ""; //営業所SID
            body.reqIdv.warehouseCd = this.warehouseCd ? this.warehouseCd : ""; //倉庫コード
            body.reqIdv.warehouseName = this.warehouseName; //倉庫名称
            body.reqIdv.warehouseNameShort = this.warehouseNameShort; //倉庫名称表示用
            body.reqIdv.warehouseNameKana = this.warehouseNameKana; //倉庫名称カナ
            body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.validFrom); //有効開始日時
            body.reqIdv.validTo = dateTimeHelper.convertUTC(this.validTo); //有効終了日時
            body.reqIdv.warehouseOwnerDiv = this.warehouseOwnerDiv ? this.warehouseOwnerDiv : ""; //社有外傭区分
            body.reqIdv.warehouseKind = this.warehouseKind ? this.warehouseKind : "";
            body.reqIdv.floorSpace = Number(this.floorSpace.replaceAll(",", ""))
              ? this.floorSpace.replaceAll(",", "")
              : ""; //延床面積(㎡)
            body.reqIdv.floorSpaceTsubo = Number(this.floorSpaceTsubo.replaceAll(",", ""))
              ? this.floorSpaceTsubo.replaceAll(",", "")
              : ""; //延床面積(坪)
            body.reqIdv.baseUnit = "m2"; //単位
            body.reqIdv.floorLoadCapacity = Number(this.floorWithstandload.replaceAll(",", ""))
              ? this.floorWithstandload.replaceAll(",", "")
              : ""; //床耐荷重
            body.reqIdv.ceilingHeight = Number(this.ceilingheight.replaceAll(",", ""))
              ? this.ceilingheight.replaceAll(",", "")
              : ""; //天井高
            body.reqIdv.deleteFlg = "0"; //削除フラグ0
          }
          // console.debug("postApi() Body", body);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.MST_ADDWAREHOUSE, body, appConfig.APP_CONFIG)
              .then((response) => {
                // console.debug("postApi() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = false;
                  this.infoDialog.sevenPageFlag = true;
                  this.infoDialog.outsideClickNotCloseFlg = true;
                  resolve(response);
                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  // reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    // 社有外傭プルダウン
    getcompanyOwnedGaijin() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.companyOwned_DIV);

      Promise.all([methodList])
        .then((result) => {
          // console.debug("getcompanyOwnedGaijin() Result", result);
          this.companyOwnedList = result[0];
          this.companyOwnedList.unshift({ name: "", text: "", value: "" });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 営業所プルダウン
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.supplierList = list;
              for (let i = 0; i < this.supplierList.length; i++) {
                if (this.officeSid == this.supplierList[i].value) {
                  this.officeNames = this.supplierList[i].text;
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 倉庫種別プルダウン
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.WAREHOUSE_TIPE);

      Promise.all([methodList])
        .then((result) => {
          // console.debug("getMstCode() Result", result);
          this.WarehouseKindList = result[0];
          this.WarehouseKindList.unshift({ name: "", text: "", value: "" });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 延床面積(㎡)カンマ処理＆計算処理
    changefloorspace(val) {
      let floorSpace = commonUtil.zen2han(val).replaceAll(",", ""); // 全角から半角に変換
      if (!floorSpace.includes(".")) floorSpace = floorSpace + ".0";
      let num = floorSpace.split(".");
      num[0] = num[0].replace(/[^0-9]/gi, "");
      num[1] = num[1].replace(/[^0-9]/gi, "");
      if (num[0].length > 8) {
        num[0] = num[0].substring(0, 8);
      }
      if (num[1].length > 2) {
        num[1] = num[1].substring(0, 2);
      }
      floorSpace = num[0] + "." + num[1];
      this.floorSpaceTsubo = Math.round(floorSpace * 0.33 * 100) / 100; // 坪への変換
      this.floorSpaceTsubo = String(commonUtil.formatToCurrency(Number(this.floorSpaceTsubo)));
      if (!isNaN(Number(floorSpace))) {
        this.floorSpace = commonUtil.formatToCurrency(Number(floorSpace));
      }
    },
    // 延床面積(坪)カンマ処理＆計算処理
    changefloorspaceTubo(val) {
      let floorSpaceTsubo = commonUtil.zen2han(val).replaceAll(",", "");
      if (!floorSpaceTsubo.includes(".")) floorSpaceTsubo = floorSpaceTsubo + ".0";
      let num = floorSpaceTsubo.split(".");
      num[0] = num[0].replace(/[^0-9]/gi, "");
      num[1] = num[1].replace(/[^0-9]/gi, "");
      if (num[0].length > 8) {
        num[0] = num[0].substring(0, 8);
      }
      if (num[1].length > 2) {
        num[1] = num[1].substring(0, 2);
      }
      floorSpaceTsubo = num[0] + "." + num[1];
      this.floorSpace = Math.round(floorSpaceTsubo * 3.3057 * 100) / 100; // ㎡への変換
      this.floorSpace = String(commonUtil.formatToCurrency(Number(this.floorSpace)));
      if (!isNaN(Number(floorSpaceTsubo))) {
        this.floorSpaceTsubo = commonUtil.formatToCurrency(Number(floorSpaceTsubo));
      }
    },
    // 床耐荷重カンマ処理
    changefloorWithstandload(val) {
      var floorWithstandload = commonUtil.zen2han(val).replaceAll(",", "");
      if (!floorWithstandload.includes(".")) floorWithstandload = floorWithstandload + ".0";
      let num = floorWithstandload.split(".");
      num[0] = num[0].replace(/[^0-9]/gi, "");
      num[1] = num[1].replace(/[^0-9]/gi, "");
      if (num[0].length > 6) {
        num[0] = num[0].substring(0, 6);
      }
      if (num[1].length > 2) {
        num[1] = num[1].substring(0, 2);
      }
      floorWithstandload = num[0] + "." + num[1];
      if (!isNaN(Number(floorWithstandload))) {
        this.floorWithstandload = commonUtil.formatToCurrency(Number(floorWithstandload));
      }
    },
    // 天井高カンマ処理
    changeceilingheight(val) {
      var ceilingheight = commonUtil.zen2han(val).replaceAll(",", "");
      if (!ceilingheight.includes(".")) ceilingheight = ceilingheight + ".0";
      let num = ceilingheight.split(".");
      num[0] = num[0].replace(/[^0-9]/gi, "");
      num[1] = num[1].replace(/[^0-9]/gi, "");
      if (num[0].length > 4) {
        num[0] = num[0].substring(0, 4);
      }
      if (num[1].length > 2) {
        num[1] = num[1].substring(0, 2);
      }
      ceilingheight = num[0] + "." + num[1];
      if (!isNaN(Number(ceilingheight))) {
        this.ceilingheight = commonUtil.formatToCurrency(Number(ceilingheight));
      }
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    formValidation() {
      this.$refs.searchForm.validate();
    },
    /**
     * 有効開始日時を－１日します。
     */
    prevDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効開始日時を＋１日します。
     */
    nextDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 有効終了日時を－１日します。
     */
    prevToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効終了日時を＋１日します。
     */
    nextToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * 日付入力(有効開始日時)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validFromCal = null;
      }
    },
    /**
     * 日付入力(有効終了日時)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validToCal = null;
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    validFromCal(val) {
      this.validFrom = this.formatDate(val);
    },
    validToCal(val) {
      this.validTo = this.formatDate(val);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}

.search-textbox-remark {
  width: 41.5rem;
  margin-left: 10px;
}
</style>
